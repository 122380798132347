import { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import cn from 'clsx'
import _isUndefined from 'lodash/isUndefined'

import { wrapAxisTick } from '../../utils'
import { CLASS_NAME } from './const'

import './style.scss'

type Props = {
  scale: any
  transform: string
  label?: string
  className?: string
  chartWidth?: number
  chartHeight?: number
}

export default function ChartAxisBottom({
  scale,
  transform,
  className,
  label,
  chartWidth,
  chartHeight
}: Props) {
  const ref = useRef<any>(null)

  const isLabelVisible =
    !_isUndefined(chartHeight) &&
    !_isUndefined(chartWidth) &&
    !_isUndefined(label) &&
    label.length > 0

  useEffect((): void => {
    const { current } = ref

    if (current === null) return

    d3.select(current)
      .call(d3.axisBottom(scale).tickSize(16))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')
      .each(wrapAxisTick)
  }, [ref.current, scale])

  return (
    <>
      {isLabelVisible && (
        <text
          x={`${(chartWidth ?? 0) / 2}`}
          y={`${(chartHeight ?? 0) + 32}`}
          className={`${CLASS_NAME}-label`}
        >
          {label}
        </text>
      )}
      <g
        className={cn(CLASS_NAME, className)}
        transform={transform}
        ref={ref}
      />
    </>
  )
}
