import _isArray from 'lodash/isArray'
import React, { Fragment, useMemo, useCallback } from 'react'

import { CHART_COLORS } from '../../config'
import { type Feature } from '../../types/data'

import { type ChartLegendProps } from './types'
import { propTypes, defaultProps } from './props'
import { CHAR_WIDTH_PX, LINE_HEIGHT_PX, CLASS_NAME } from './const'

import './style.scss'

const ChartLegend: React.FC<ChartLegendProps> = (props: ChartLegendProps) => {
  const { x, y, chartWidth, emissions, setEmissionsHovered } = props
  const features = useMemo(
    (): Feature[] =>
      _isArray(emissions)
        ? emissions.map(({ feature }) => feature)
        : [emissions.feature],
    [emissions]
  )

  const onLabelMouseOver = useCallback(
    (i: number) => {
      setEmissionsHovered(i, true)
    },
    [setEmissionsHovered]
  )

  const onLabelMouseOut = useCallback(
    (i: number) => {
      setEmissionsHovered(i, false)
    },
    [setEmissionsHovered]
  )

  let currentX = x
  let currentY = y
  let maxDelta = 0

  return features.map((feature: Feature, i: number) => {
    const label =
      feature?.properties?.name ?? feature?.properties?.feature_uuid ?? ''
    const labelLength = CHAR_WIDTH_PX * label.length + 48
    const labelDelta = labelLength + 32

    if (labelDelta > maxDelta) {
      maxDelta = labelDelta
    }

    const result = (
      <Fragment key={`chart-legend-feature-${i}`}>
        <circle
          onMouseOver={onLabelMouseOver.bind(null, i)}
          onMouseOut={onLabelMouseOut.bind(null, i)}
          fill={CHART_COLORS[i % CHART_COLORS.length]}
          cx={currentX + 8}
          cy={currentY + 60}
          r={5}
        />

        <text
          className="cst-chart-legend-text"
          onMouseOver={onLabelMouseOver.bind(null, i)}
          onMouseOut={onLabelMouseOut.bind(null, i)}
          x={currentX + 16}
          y={currentY + 64}
        >
          {label}
        </text>
      </Fragment>
    )

    currentX += labelDelta

    if (currentX - x > chartWidth * 0.75) {
      currentX = x
      currentY += LINE_HEIGHT_PX
    }

    return result
  })
}

ChartLegend.propTypes = propTypes
ChartLegend.defaultProps = defaultProps

export default ChartLegend
export { CLASS_NAME }
export type { ChartLegendProps as PROPS }
