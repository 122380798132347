import { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import cn from 'clsx'
import _isUndefined from 'lodash/isUndefined'

import { CLASS_NAME } from './const'

import './style.scss'

type Props = {
  scale: any
  label?: string
  className?: string
  chartWidth?: number
  chartHeight?: number
}

export default function ChartAxisLeft({
  scale,
  label,
  className,
  chartWidth,
  chartHeight
}: Props) {
  const ref = useRef<any>(null)

  useEffect((): void => {
    const { current } = ref

    if (current === null) return

    d3.select(current).call(d3.axisLeft(scale))
  }, [ref.current, scale])

  return (
    <>
      {!_isUndefined(label) && label.length > 0 && (
        <svg width={chartWidth} height={chartHeight}>
          <g transform={`translate(${chartWidth}, 0)`}>
            <text
              x={`-${(chartHeight ?? 0) / 2}`}
              y={`-${(chartWidth ?? 0) + 80}`}
              transform="rotate(-90)"
              className={`${CLASS_NAME}-label`}
            >
              {label}
            </text>
          </g>
        </svg>
      )}
      <g className={cn(CLASS_NAME, className)} ref={ref} />
    </>
  )
}
